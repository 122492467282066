import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    name: "User",
    redirect: "/enter",
  },
  {
    path: "/enter",
    name: "Enter",
    component: () =>
      import(/* webpackChunkName: "enter" */ "@/views/enter/index.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "@/views/user/Login.vue"),
  },
  {
    path: "/forgot",
    name: "Forgot",
    component: () =>
      import(/* webpackChunkName: "forgot" */ "@/views/user/Forgot.vue"),
  },
  {
    path: "/register",
    name: "Register",
    component: () =>
      import(/* webpackChunkName: "register" */ "@/views/user/Register.vue"),
  },
  {
    path: "/fillAgain",
    name: "FillAgain",
    component: () =>
      import(/* webpackChunkName: "register" */ "@/views/form/fillAgain.vue"),
  },
  {
    path: "/home",
    name: "Home",
    component: () =>
      import(/* webpackChunkName: "home" */ "@/views/home/index.vue"),
  },
  {
    path: "/homeCheck",
    name: "HomeCheck",
    component: () =>
      import(/* webpackChunkName: "home" */ "@/views/home/homeCheck.vue"),
  },
  {
    path: "/notice",
    name: "Notice",
    component: () =>
      import(/* webpackChunkName: "notice" */ "@/views/notice/index.vue"),
  },
  {
    path: "/notice2",
    name: "Notice2",
    component: () =>
      import(/* webpackChunkName: "notice" */ "@/views/notice/index2.vue"),
  },
  {
    path: "/noticeDetail",
    name: "NoticeDetail",
    component: () =>
      import(/* webpackChunkName: "notice" */ "@/views/notice/detail.vue"),
  },
  {
    path: "/qa",
    name: "QA",
    component: () =>
      import(/* webpackChunkName: "qa" */ "@/views/qa/index.vue"),
  },
  {
    path: "/qaDetail",
    name: "QADetail",
    component: () =>
      import(/* webpackChunkName: "qa" */ "@/views/qa/detail.vue"),
  },
  {
    path: "/SecondSelectionReport",
    name: "SecondSelectionReport",
    component: () =>
      import(/* webpackChunkName: "register" */ "@/views/form/secondSelectionReport.vue"),
  },
  {
    path: "/xqImg",
    name: "XqImg",
    component: () =>
      import(/* webpackChunkName: "xq" */ "@/views/xqImg/index.vue"),
  },
];

const router = new VueRouter({
  // mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  base: process.env.BASE_URL,
  routes,
});
export default router;
